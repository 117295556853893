// @flow
import BaseModel from "./BaseModel";
import User from "./User";

class Event extends BaseModel {
  id: string;
  name: string;
  modelId: ?string;
  actorId: string;
  actorIpAddress: ?string;
  documentId: string;
  collectionId: ?string;
  userId: string;
  createdAt: string;
  actor: User;
  data: {
    name: string,
    email: string,
    title: string,
    published: boolean,
    templateId: string,
  };
}

export default Event;
